import React, { useState, useEffect } from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaRegTimesCircle } from "react-icons/fa";
import { BsFillHouseFill } from "react-icons/bs";
import "../styles/Navbar.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
// import Logo from './../Images/logo1.png';
const Navbar = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 1, delay: 0.5 }}
			className="navbar"
		>
			<div className="container">
				<NavLink to="/" onClick={closeMobileMenu}>
					<img
						className="main-logo"
						src={
							"https://res.cloudinary.com/rivach/image/upload/v1649412056/AVK-innovations/logo1_ezwag8.png"
						}
						alt=""
					/>
				</NavLink>
				<ul className={click ? "nav-menu active" : "nav-menu"}>
					<NavLink to="/about" onClick={closeMobileMenu}>
						<li>About Us</li>
					</NavLink>
					<NavLink to="/careers" onClick={closeMobileMenu}>
						<li>Careers</li>
					</NavLink>
					<NavLink to="/services" onClick={closeMobileMenu}>
						<li>Services</li>
					</NavLink>
					<NavLink to="/blogs" onClick={closeMobileMenu}>
						<li>Blogs</li>
					</NavLink>
					<NavLink to="/contact" onClick={closeMobileMenu}>
						<li>Contact Us </li>
					</NavLink>
					<NavLink to="/appointment" onClick={closeMobileMenu}>
						<button className="btn1">Talk to our Team</button>
					</NavLink>
				</ul>
				<div className="hamburger" onClick={handleClick}>
					{click ? (
						<FaRegTimesCircle className="icon" />
					) : (
						<HiOutlineMenuAlt4 className="icon" />
					)}
				</div>
			</div>
		</motion.div>
	);
};
export default Navbar;
