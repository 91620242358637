import React, { Suspense, lazy } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import Navbar from "./components/Navbar";
// import Home from "./Pages/Home";
// import Careers from "./Pages/Careers";
// import Blogs from "./Pages/Blogs";
// import Services from "./Pages/Services";
// import Contact from "./Pages/Contact";
// import NotFound from "./Pages/NotFound"
// import Footer from "./components/Footer"
// import CareersDetailed from "./components/careers/CareersDetailed";
// import BlogDetailed from "./components/Blog/BlogDetailed";
// import AboutUs from "./Pages/AboutUs";
// import WithSubnavigation from "./components/Navbar1";

const client = new ApolloClient({
	uri: "https://avkinnovations-cms.herokuapp.com/graphql",
	cache: new InMemoryCache(),
});

const Home = lazy(() => import("./Pages/Home"));
const Careers = lazy(() => import("./Pages/Careers"));
const Blogs = lazy(() => import("./Pages/Blogs"));
const Services = lazy(() => import("./Pages/Services"));
const Contact = lazy(() => import("./Pages/Contact"));
const Appointment = lazy(() => import("./Pages/Appointment"));
const NotFound = lazy(() => import("./Pages/NotFound"));
// const Navbar = lazy(() => import("./components/Navbar"));
const Footer = lazy(() => import("./components/Footer"));
const CareersDetailed = lazy(() =>
	import("./components/careers/CareersDetailed")
);
const BlogDetailed = lazy(() => import("./components/Blog/BlogDetailed"));
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const WithSubnavigation = lazy(() => import("./components/Navbar1"));

const App = () => {
	return (
		<ApolloProvider client={client}>
			<div className="App">
      <CookieConsent
        debug={true}
        flipButtons
        enableDeclineButton
				location="bottom"
				buttonText="I Understand"
				cookieName="AVK-cookie"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
        onDecline={
          () => {
            alert("Are You Sure?");
          }
        }
			>
				This website uses cookies to enhance the user experience.{" "}
				<span style={{ fontSize: "10px" }}>
					Check Our <a>Privacy Policy</a>
				</span>
			</CookieConsent>
				<Helmet>
					<meta charSet="utf-8" />
					<title>AVK INNOVATIONS</title>
					<meta
						name="description"
						content="We Provide IT and Business Solutions"
					/>
					<meta
						name="keywords"
						content="AVK Innovations IT-solutions Business"
					/>
				</Helmet>
				<Router>
					<Navbar />
					{/* <WithSubnavigation /> */}
					<Suspense fallback={<div></div>}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/about" element={<AboutUs />} />
							<Route path="/careers" element={<Careers />} />
							<Route path="/jobs">
								<Route path=":JobId" element={<CareersDetailed />} />
							</Route>
							<Route path="/blogs" element={<Blogs />} />
							<Route path="/blogs">
								<Route path=":BlogId" element={<BlogDetailed />} />
							</Route>
							<Route path="/contact" element={<Contact />} />
							<Route path="/services" element={<Services />} />
							<Route path="/appointment" element={<Appointment/>}/>
							<Route path="*" element={<NotFound />} />
						</Routes>
						<Footer />
					</Suspense>
				</Router>
			</div>
		</ApolloProvider>
	);
};

export default App;
